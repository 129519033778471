import { render, staticRenderFns } from "./vipairportService.vue?vue&type=template&id=b8d0e7de&scoped=true"
var script = {}


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8d0e7de",
  null
  
)

export default component.exports