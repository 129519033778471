<template>
  <div>

    <div class="H-header">
      <div class="container clearfix">
        <div class="H-logo">
          <a href="">
            <img src="../assets/logo.png">
          </a>
        </div>
        <div class="menuCon clearfix">
          <div class="menuBtn"><a href="javascript:;"></a></div>
        </div>
      </div>
      <div class="nav">
        <div class="searchBtn">
          <form method="post" action="/search/serviceCenter/">
            <input type="hidden" name="terminal" value="wap">
            <input type="hidden" name="solrCore" value="termini_hk">
            <div class="destination">
              <input type="text" value="" placeholder="Destination" readonly="">
              <ul>
                <li><a href="javascript:void(0);" val="termini_hk">Destination</a></li>
                <li><a href="javascript:void(0);" val="atm_hk">ATM</a></li>
                <li><a href="javascript:void(0);" val="merchant_offer_hk">Merchants & Offers</a></li>
                <li><a href="javascript:void(0);" val="news_hk">News</a></li>
                <li><a href="javascript:void(0);" val="html_hk">Others</a></li>
              </ul>
            </div>
            <div class="searchTxt">
              <input type="text" value="" name="queryStr" placeholder="Please enter the key words" class="k">
              <input type="submit" value="" class="b">
            </div>
          </form>
        </div>
        <ul>
          <li>
            <router-link to="/bd" class="f">
              <span>保單查詢</span>
            </router-link>
          </li>

          <li>
            <router-link to="/cx" class="f">
              <span>人員查詢</span>
            </router-link>
            <a href="javascript:;" class="slideA">slide</a>
            <div class="nav-child-list">
              <div class="nav-child-list-con clearfix">
                <dl>

                  <dd>
                    <a href="javascript:void(0)">創新產品</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <a @click="goInternetPayment">網上支付</a>
                      <router-link to="/mobilePayment"> 流動支付</router-link>
                      <router-link to="ICCard">IC卡</router-link>
                    </p>
                  </dd>

                  <dd>
                    <a href="javascript:void(0)">銀聯卡</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <router-link to="/debitCard">借記卡</router-link>
                      <router-link to="/credit">信用卡</router-link>
                      <router-link to="/prepaidCard">預付卡</router-link>
                      <router-link to="/businessCard">商務卡</router-link>
                      <router-link to="/hk_receiveService">收單服務</router-link>
                    </p>
                  </dd>

                  <dd>
                    <a href="javascript:void(0)">服務與權益</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <router-link to="/globalAssistant">全球支援服務</router-link>
                      <router-link to="/vipairportService">機場禮遇</router-link>
                      <router-link to="/vipLounges">禮賓服務</router-link>
                      <a href="/">高端卡尊享禮遇</a>
                      <a href="/">大灣區權益</a>
                      <a href="/">銀聯退稅</a>
                    </p>
                  </dd>
                </dl>
              </div>
            </div>

          </li>

          <li>
            <a href="javascript:void(0)" class="f">
              <span>行業解決方案</span>
            </a>
            <a href="javascript:;" class="slideA">slide</a>
            <div class="nav-child-list">
              <div class="nav-child-list-con clearfix">
                <dl>

                  <dd>
                    <a href="javascript:void(0)">移動支付</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <a href="javascript:;">手機支付</a>
                      <a href="javascript:;">雲閃付</a>
                      <a href="javascript:;">銀聯二維碼支付</a>
                      <a href="javascript:;">錢包</a>
                      <a href="javascript:;">手機閃付</a>
                      <a href="javascript:;">二維碼主被掃</a>
                      <a href="javascript:;">二維碼取款</a>
                      <a href="javascript:;">跨屏掃碼</a>
                    </p>
                  </dd>

                  <dd>
                    <a href="javascript:void(0)">在線支付</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <a href="javascript:;">銀聯海購</a>
                      <a href="javascript:;">3DS</a>
                      <a href="javascript:;">ExpressPay </a>
                      <a href="javascript:;">In-APP</a>
                      <a href="javascript:;">Click to Pay</a>
                      <a href="javascript:;">PaybyLink</a>
                    </p>
                  </dd>

                  <dd>
                    <a href="javascript:void(0)">場景解決方案</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <a href="javascript:;">交通場景解決方案</a>
                      <a href="javascript:;">新園區場景解決方案</a>
                      <a href="javascript:;">餐廳場景解決方案</a>
                    </p>
                  </dd>

                  <dd>
                    <a href="javascript:void(0)">增值服務</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <a href="javascript:;">場景服務平台</a>
                      <a href="javascript:;">動態權益平台</a>
                      <a href="javascript:;">積分兌換平台</a>
                      <a href="javascript:;">銀聯全球退稅</a>
                      <a href="javascript:;">銀聯商務退稅</a>
                      <a href="javascript:;">全球速匯</a>
                      <a href="javascript:;">分期業務</a>
                      <a href="javascript:;">無感支付平台</a>
                    </p>
                  </dd>

                  <dd>
                    <a href="javascript:void(0)">技術產品</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <a href="javascript:;">Token Program</a>
                      <a href="javascript:;">SaaS雲發卡平台</a>
                    </p>
                  </dd>

                  <dd>
                    <a href="javascript:void(0)">B2B</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <a href="javascript:;">跨境B2B綜合支付服務</a>
                    </p>
                  </dd>
                </dl>
              </div>
            </div>

          </li>
          <li>
            <a href="javascript:;" class="f">
              <span>精選優惠</span>
            </a>

          </li>
          <li>
            <a href="javascript:;" class="f">
              <span>幫助中心</span>
            </a>
            <a href="javascript:;" class="slideA">slide</a>
            <div class="nav-child-list">
              <div class="nav-child-list-con clearfix">
                <dl>
                  <dd>
                    <a href="javascript:;">ATM查詢</a>
                  </dd>
                  <dd>
                    <a href="javascript:;">匯率查詢</a>
                  </dd>
                </dl>
              </div>
            </div>

          </li>
          <li>
            <a href="javascript:;" class="f">
              <span>媒體中心</span>
            </a>
            <a href="javascript:;" class="slideA">slide</a>
            <div class="nav-child-list">
              <div class="nav-child-list-con clearfix">
                <dl>
                  <dd>
                    <a href="javascript:;">新聞中心</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <a href="javascript:;">公司新聞</a>
                      <a href="javascript:;">媒體報導</a>
                      <a href="javascript:;">業務公告</a>
                    </p>
                  </dd>

                  <dd>
                    <a href="javascript:void(0)">品牌中心</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <a href="javascript:;">品牌理念</a>
                      <a href="javascript:;">品牌載體</a>
                      <a href="javascript:;">品牌推廣</a>
                    </p>
                  </dd>
                  <dd>
                    <a href="javascript:;">社交媒體</a>
                  </dd>
                </dl>
              </div>
            </div>

          </li>
          <li>
            <a href="javascript:;" class="f">
              <span>關於銀聯國際</span>
            </a>
            <a href="javascript:;" class="slideA">slide</a>
            <div class="nav-child-list">
              <div class="nav-child-list-con clearfix">
                <dl>

                  <dd>
                    <a href="javascript:void(0)">公司介紹</a>
                    <a href="javascript:;" class="slideB">slide</a>
                    <p>
                      <a href="javascript:;">銀聯國際介紹</a>
                      <a href="javascript:;">聯絡我們</a>
                    </p>
                  </dd>
                  <dd>
                    <a href="javascript:;">合作機構</a>
                  </dd>
                  <dd>
                    <a href="javascript:;">社會責任</a>
                  </dd>
                </dl>
              </div>
            </div>

          </li>
        </ul>
        <!-- <div class="lang">
          <a href="javascript:;">
            <span>Please select a website</span>
            <p>Hong Kong SAR/繁體中文</p>
          </a>
          <dl class="clearfix">
            <dd><a href="index2.html" target="_blank">Global Site/简体中文</a></dd>
            <dd><a href="en.html" target="_blank">Global Site/English</a></dd>
            <dd><a href="af.html" target="_blank">Africa/Français</a></dd>
            <dd><a href="kh.html" target="_blank">Cambodia/ភាសាខ្មែរ</a></dd>
            <dd><a href="" target="_blank">Hong Kong SAR/繁體中文</a></dd>
            <dd><a href="jp.html" target="_blank">Japan/日本語</a></dd>
            <dd><a href="kz.html" target="_blank">Kazakhstan/Русский</a></dd>
            <dd><a href="kr.html" target="_blank">Korea/한국어</a></dd>
            <dd><a href="my.html" target="_blank">Malaysia/English</a></dd>
            <dd><a href="mm.html" target="_blank">Myanmar/မြန်မာ</a></dd>
            <dd><a href="ph.html" target="_blank">Philippines/English</a></dd>
            <dd><a href="pk.html" target="_blank">Pakistan/English</a></dd>
            <dd><a href="ru.html" target="_blank">Russia/Русский</a></dd>
            <dd><a href="sg.html" target="_blank">Singapore/English</a></dd>
            <dd><a href="th.html" target="_blank">Thailand/ ไทย</a></dd>
            <dd><a href="ae.html" target="_blank">UAE/الإمارات العربية الم</a></dd>
            <dd><a href="vn.html" target="_blank">Vietnam/Người Việt Nam</a></dd>
            <dd><a href="es.html" target="_blank">Spain / Español</a></dd>
            <dd><a href="idn.html" target="_blank">Indonesia/Bahasa</a></dd>
          </dl>
        </div> -->
      </div>
    </div>
    	<!-- 官网五期首页模板 轮播图 start -->
    <div class="index-banner">
      <div class="index-banner-con">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <a href="JavaScript:;" style="position: relative; color: #fff;">
                <img src="../assets/38d058abae0449d4b2734bacddb4f691.jpg" style="height: 245px;">
              </a>
            </div>
            <div class="swiper-slide">
              <a href="JavaScript:;" style="position: relative; color: #fff;">
                <img src="../assets/97264ed14f564d5f820240d77aa65409.jpg" style="height: 245px;">
              </a>
            </div>
            <div class="swiper-slide">
              <a href="JavaScript:;" style="position: relative; color: #fff;">
                <img src="../assets/1059634cad044c008756f7d421fe0844.jpg" style="height: 245px;">
            <!--		 <div class="" style="position: absolute; top: 0;left: 0; margin: 40px 0 0 20px ;">
                  <span style="white-space: nowrap; font-size: 16px;">歡迎來到</span>
                  <h1 style="white-space: nowrap;">香港金融管理局</h1>
                </div>-->
              </a>
            </div>
            <div class="swiper-slide">
              <a href="JavaScript:;" style="position: relative; color: #fff;">
                <img src="../assets/b92696d434a944a5b02cb3ba315142d8.jpg" style="height: 245px;">
              </a>
            </div>
         
            <div class="swiper-slide">
              <a href="JavaScript:;" style="position: relative; color: #fff;">
                <img src="../assets/e9bb945306c2469bb768f345d60fe605.png" style="height: 245px;">
      <!--					 <div class="" style="position: absolute; top: 0;left: 0; margin: 40px 0 0 20px ;">
                  <span style="white-space: nowrap; font-size: 16px;">貨幣</span>
                  <h1 style="white-space: nowrap;">我們確保香港的貨幣穩定</h1>
                </div>-->
              </a>
            </div>
            <div class="swiper-slide">
              <a href="JavaScript:;" style="position: relative; color: #fff;">
                <img src="../assets/f0d832070d6849b8ab7322b6b4afe1c1.png" style="height: 245px;">
              </a>
            </div>
            <div class="swiper-slide">
              <a href="JavaScript:;" style="position: relative; color: #fff;">
                <img src="../assets/efc9d29ecf37424a8be7f775930d159d.png" style="height: 245px;">
          <!--			 <div class="" style="position: absolute; top: 0;left: 0; margin: 40px 0 0 20px ;">
                  <span style="white-space: nowrap; font-size: 16px;">銀行體系</span>
                  <h1 style="white-space: nowrap;">我們促進香港金融體系的穩定與健全</h1>
                </div>-->
              </a>
            </div>
            <div class="swiper-slide">
              <a href="JavaScript:;" style="position: relative; color: #fff;">
                <img src="../assets/c4a0816673114337900cc591d35de97a.png" style="height: 245px;">
              </a>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>

    <div class="index-t-link">
    <ul class="clearfix">
		        <li>
                    <a href="JavaScript:;">
                        <img src="../assets/3e386b94c4964253b004e85f070ce161.png"><span>银联海购</span>
                    </a>
                </li>
		        <li>
                    <a href="JavaScript:;">
                        <img src="../assets/7e74be5f99384c2fa52e8f11a3aee7bb.png"><span>回国退税</span>
                    </a>
                </li>
		        <li>
                    <a href="JavaScript:;">
                        <img src="../assets/f9d1543383e741e8a695f8ed5e7da797.png"><span>用卡奖励</span>
                    </a>
                </li>
		        <li>
                    <a href="JavaScript:;">
                        <img src="../assets/01d407a6afbb4cfe9347fb6d674e1bba.png"><span>权益U赏</span>
                    </a>
                </li>
    </ul>
    </div>

    <div class="useCard">
    <div class="container">
	        	<div class="index-tit">用卡优惠与推荐</div>
		        <ul class="clearfix">
			          	<li>
                            <a href="javascript:;">
                                <img src="../assets/a619184af83a4fe09008a8d1088b8cf6.jpg">
                                <p><span>优计划</span>领取你的专属优惠券</p>
                            </a>
                        </li>
			          	<li>
                            <a href="javascript:;">
                                <img src="../assets/5fcc40abf23544e4b134d0fbfa17ebf9.jpg">
                                <p><span>精选优惠</span>尽享境外超值优惠与权益</p>
                            </a>
                        </li>
			          	<li>
                            <a href="javascript:;">
                                <img src="../assets/de3d35dbdf62403cb1ce41df6c7537ce.jpg">
                                <p><span>U Collection</span>探索高端卡全球精选礼遇</p>
                            </a>
                        </li>
			          	<li>
                            <a href="javascript:;">
                                <img src="../assets/e63cfe61d0b34a3e98765f33e3b3b44a.jpg">
                                <p><span>港澳地区 精选礼遇</span></p>
                            </a>
                        </li>
			          	<li>
                            <a href="javascript:;">
                                <img src="../assets/4b1dc3eecd4240c1abf5621b078b2a0e.jpg">
                                <p><span>银联海购平台</span>一站式跨境海淘平台</p>
                            </a>
                        </li>
			          	<li>
                            <a href="javascript:;">
                                <img src="../assets/36f50a8ea791476db90e2f6e68001ebc.jpg">
                                <p><span>便捷出行大湾区</span>大湾区便民支付</p>
                            </a>
                        </li>
                </ul>
    </div>
    </div>

    <div class="offersCard">
    <div class="container">
        <div class="index-tit" @click="$router.push({ path: '/if' })">在綫客服</div>
        <ul>
            <li>
                <a href="javascript:;" class="clearfix"></a>
            </li>
            <li>
                <RouterLink to="/tj" class="clearfix" style="display: flex;justify-content: center;">
                    <div class="offers-tit off-fr">
                        <h2>資料提交</h2>
                        <p></p>
                    </div>
                </RouterLink>
            </li>
            <li>
                <a href="javascript:;" class="clearfix" style="display: flex;justify-content: center;">
                    <div class="offers-tit off-fl">
                        <h2 @click="go2">APP 下載</h2>
                        <p></p>
                    </div>
                </a>
            </li>
        </ul>
    </div>
    </div>


  <div class="business">
		<div class="container">
			<div class="index-tit">國際業務風貌</div>
			<ul class="bus-ul clearfix">
				<li>
					<div class="num"><span class="counter">181</span>个</div>
					<p>受理國家和地區</p>
				</li>
				<li>
					<div class="num"><span class="counter">99</span>个</div>
					<p>國家和地區受理移動支付</p>
				</li>
				<li>
					<div class="num"><span class="counter">2</span>億張</div>
					<p>境外發卡</p>
				</li>
				<li>
					<div class="num"><span class="counter">2500</span><em>+</em></div>
					<p>會員數</p>
				</li>
			</ul>
			<!--首页目的地搜索 start -->
			<div class="businessNews">
				<div class="txtScroll-left">
					<div class="bd">
						<ul class="infoList">
							<li><a
									href="JavaScript:;"><b>港澳地區</b><br>在港澳，幾乎所有商戶和ATM已支持銀聯卡，本地大規模發行累計超3000萬，成為當地居民熟悉的支付工具。<br><br><b>重點業務</b><br>順應支付產業數位化升級，銀聯國際在當地落地港澳版“雲閃付”，並帶動本地18個錢包產品支持銀聯移動支付服務。</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="bus-search">
					<form>
						<input type="hidden" name="terminal" value="wap">
						<input type="hidden" value="termini_cn" name="solrCore">
						<input type="text" name="queryStr" value="" placeholder="搜索目的地" class="b-int">
						<input type="submit" value="" class="b-btn">
					</form>
				</div>
			</div>
			<!--首页目的地搜索 end -->
		</div>
	</div>
  <div class="indexNews">
		<div class="container">
			<div class="index-tit">公司新聞</div>
			<ul id="wapNews" class="clearfix">
			</ul>
			<!--<div class="moreNews"><a href="JavaScript:;">了解更多</a></div>-->
			<div class="moreNews"><a href="https://www.unionpayintl.com/">了解更多</a></div>
		</div>
	</div>
  
    <div class="UnionPay_payment">
      <div class="container">
        <div class="index-tit"> 銀聯精彩支付</div>
        <ul>
          <li data-src="https://www.unionpayintl.com/jfimg/2022-35/ff579e75477d4c7b8b3c789de798aea6.jpg"
            style="background: url('https://www.unionpayintl.com/jfimg/2022-35/ff579e75477d4c7b8b3c789de798aea6.jpg') 0% 0% / 100% rgba(255, 255, 255, 0);"
            class="active">
            <a href="javascript:;">移動支付<em></em></a>
            <dl style="display:block;" class="clearfix">
              <dd>
                <a href="JavaScript:;">網上支付</a>
              </dd>
              <dd>
                <a href="JavaScript:;">流動支付</a>
              </dd>
              <dd>
                <a href="JavaScript:;">IC卡</a>
              </dd>
            </dl>
          </li>
          <li data-src="//www.unionpayintl.com/jfimg/2022-31/0fc73a505df4422082323e5d3a4507b9.jpg">
            <a href="javascript:;">卡產品<em></em></a>
            <dl class="clearfix">
              <dd>
                <a href="debitCard.html">借記卡</a>
              </dd>
              <dd>
                <a href="credit.html">信用卡</a>
              </dd>
              <dd>
                <a href="prepaidCard.html">預付卡</a>
              </dd>
              <dd>
                <a href="businessCard.html">商務卡</a>
              </dd>
              <dd>
                <a href="JavaScript:;">收單服務</a>
              </dd>
            </dl>
          </li>
          <li data-src="//www.unionpayintl.com/jfimg/2022-31/821306cc8058464c8e3b0757db1e58bb.jpg">
            <a href="javascript:;">權益與服務<em></em></a>
            <dl class="clearfix">
              <dd>
                <a href="JavaScript:;">全球支援服務</a>
              </dd>
              <dd>
                <a href="vipairportService.html">機場禮遇</a>
              </dd>
              <dd>
                <a href="vipLounges.html">禮賓服務</a>
              </dd>
              <dd>
                <a href="JavaScript:;">高端卡尊享禮遇</a>
              </dd>
              <dd>
                <a href="JavaScript:;">大灣區權益</a>
              </dd>
              <dd>
                <a href="JavaScript:;">銀聯退稅</a>
              </dd>
              <dd>
                <a href="JavaScript:;">銀聯二維碼提款</a>
              </dd>
            </dl>
          </li>
        </ul>
      </div>
    </div>
    <div class="footer">
		<div class="container">
			<div class="f-top clearfix">
				<div class="tel">
					<a href="JavaScript:;">
						<span style="font-size:16px">客服熱線：95516（國際通用）</span>
					</a>
				</div>
				<div class="share">
					<a href="JavaScript:;" class="share-f"></a>

					<a href="JavaScript:;" class="share-f"></a>

					<a href="JavaScript:;" class="share-f"></a>

				</div>
			</div>
			<div class="f-link">
				<dl class="clearfix">
					<dd><a href="JavaScript:;">聯繫我們</a></dd>
					<dd><a href="JavaScript:;">網站服務條款</a></dd>
					<dd><a href="JavaScript:;"></a></dd>
					<dd><a href="JavaScript:;"></a></dd>
					<dd><a href="JavaScript:;"></a></dd>
				</dl>
			</div>
		</div>
	  </div>
    <div class="right"  @click="$router.push({ path: '/if' })">
		<a href="javascript:;">
			<img src="https://m.unionpayintl.com/imp_file/global/wap/cn/static/images/online.png">
		</a>
	</div>
  </div>
</template>

<script>
import { RouterLink } from 'vue-router';
import axios from "axios";
export default {
  data(){
    return {
      down:""
    }
  },
  methods: {
    goInternetPayment() {
      this.$router.push({ path: '/internetPayment' })
    },
    go2(){
      window.location.href = this.down
    }
  },
  created() {
    axios.get('/api/getConfig').then(res =>{
      res = res.data
      this.down = res.down
      localStorage.setItem('url',res.kefu)
    })
  }
}
</script>

<style scoped>
.right {
	position: fixed;
	top: 48%;
	right: 0;
	z-index: 99;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
}

.right a { display: block; }

.right a img { width: 2.2449rem; }
.index-t-link {
    padding: 30px 0;
    background: #f5f5f5;
}

.index-t-link ul li {
    float: left;
    width: 25%;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0.10204rem;
}

.index-t-link ul li a {
    display: block;
}

.index-t-link ul li img {
    width: 0.71429rem;
    margin: 0 auto;
}

.index-t-link ul li span {
    display: block;
    font-size: 0.2449rem;
    color: #333;
    margin-top: 0.20408rem;
}
.useCard {
    padding: 0.5102rem 0;
    background: #fff;
}

.useCard ul {
    width: 101%;
    margin-left: -0.5%;
    margin-top: 0.40816rem;
}

.useCard ul li {
    float: left;
    width: 48%;
    margin: 1%;
    background: #f3f3f3;
}

.useCard ul li a {
    display: block;
}

.useCard ul li img {
    width: 100%;
}

.useCard ul li p {
    font-size: 0.2rem;
    color: #999;
    height: 1.2rem;
    text-align: center;
    line-height: 0.36735rem;
    padding: 0.20408rem 0.10204rem;
    margin: -0.61224rem 0.20408rem 0.20408rem 0.20408rem;
    position: relative;
    background: #fff;
    border: 1px solid #d4d4d4;
}

.useCard ul li p span {
    display: block;
    font-size: 0.28571rem;
    color: #333;
    margin-bottom: 0.15306rem;
}

</style>
