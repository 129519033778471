<script>
export default {
  name: "formHt",
  data() {
    return {
      sn: [],
      name: "",
      code: "",
      year: "",
      month: "",
      day: "",
      startTime: "",
      endTime: "",
      divStyle: {
        background: `url(${require("../assets/a.jpg")}) no-repeat`,
        backgroundPosition: "97% 50%",
        backgroundSize: "27% 82%",
      },
    };
  },
  methods: {
    getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      var encodedValue = results[2].replace(/\+/g, " ");
      return decodeURIComponent(encodedValue);
    },
    formatDateToChinese(date) {
      let year = date.getFullYear();
      let month = date.getMonth() + 1; // 月份从 0 开始，需要加 1
      let day = date.getDate();
      // 拼接为 "YYYY年M月D日" 的格式
      return year + " 年 " + month + " 月 " + day + " 日";
    },
    // 使用一个简单的哈希函数来生成数字
    simpleHash(inputString) {
      let hash = 0;
      for (let i = 0; i < inputString.length; i++) {
        hash = inputString.charCodeAt(i) + ((hash << 5) - hash);
      }
      return hash;
    },
    // 将哈希值转换为10位数字
    hashTo10Digits(inputString) {
      let hash = this.simpleHash(inputString);
      // 取hash的绝对值（确保为非负数），然后转换为字符串
      let hashStr = Math.abs(hash).toString();
      // 补全到10位数字
      while (hashStr.length < 10) {
        hashStr = "0" + hashStr;
      }
      // 取字符串的末尾10位数字作为结果
      let result = hashStr.slice(-10);
      return result;
    },
    redirectToHomepage() {
      window.location.href = '/'
    }
  },

  created() {
    this.code = this.$route.query.code;
    this.name = this.$route.query.name;
    let encryptedString = this.hashTo10Digits(this.code + this.name)
        .substring(0, 10)
        .toUpperCase();
    let currentDate = new Date();
    let year = currentDate.getFullYear().toString();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    let day = currentDate.getDate().toString().padStart(2, "0");
    let yyyymmdd = year + month + day;
    this.sn = yyyymmdd + encryptedString;
    this.sn =  'TXWB20243897789300'
        this.startTime = this.formatDateToChinese(currentDate);
    let endTimeValue = new Date();
    let newYear = endTimeValue.getFullYear() + 3;
    endTimeValue.setFullYear(newYear);
    this.endTime = this.formatDateToChinese(endTimeValue);
  },
};
</script>

<template>
  <div class="container">
    <div class="header d-a-j">
      <span>保单查询</span>
      <svg
          @click="redirectToHomepage"
          class="icon"
          viewBox="0 0 1024 1024"
          width="16"
          height="16"
      >
        <path
            d="M806.4 263.2l-45.6-45.6L512 467.2 263.2 217.6l-45.6 45.6L467.2 512 217.6 760.8l45.6 45.6L512 557.6l248.8 248.8 45.6-45.6L557.6 512z"
            fill="#ffffff"
        ></path>
      </svg>
    </div>
    <div class="" style="padding: 0 15px;margin: 10px 0;display: flex;justify-content: space-between;">
      <img style="width: 45%;" src="../assets/images/logo.png"/>
      <span style="color: #ff0000; font-size: 15px;align-self: end;">腾讯微保电子账户安全险</span>
    </div>
    <div class="body">
      <div class="item m-t d-a">
        <span style="margin-right: 10px">保单号码：{{ sn }} </span>
        <span>幣值單位：人民幣 </span>
      </div>

      <div class="item m-t d-a-j">
        <span>保单生成日期：{{ startTime }}-{{ endTime }} </span>
      </div>

      <div class="item">
        <span>投被保险人资料 </span>
      </div>
      <div class="d-f-a">
        <span class="span">投保人： 微保中心</span>
        <span class="span"
        >被保人姓名：{{ name }} 保險購買方式：微保人保財險（账户安全險）</span
        >
      </div>
      <div class="item m-t d-a-j">
        <span>保险计划：资金保障保险 </span>
      </div>
      <div class="d-j">
        <div class="left d-f-a">
          <h4>保險責任</h4>
          <span>適用條款：微保安心賠（可在微信第三方共享清單查看保單詳情）</span>
          <span>保險類型：微保人保財險（账户安全險）</span>
          <span>保險費用：720 元/月，3 年合計 25920 元。</span>
          <span>保障範圍：個人支付賬戶（銀行卡,零錢,零錢通,理財通,信用賬戶等）</span>
          <span>保障查詢：提供第三方支付平台辦理業務查詢保險服務（微信,支付寶） </span>
          <span
          >保障說明：個人支付賬戶因被他人盜用而導致的資金損失,按損失金額承諾賠付,每年累積理賠次數不限次數。</span
          >
        </div>
        <div class="right" style="width: 170px">
          <img src="../assets/images/z.png" width="100%" style="margin-top: 10px"/>
        </div>
      </div>
      <div class="item" style="margin: 10px 0">
        <span>特别约定 </span>
      </div>
      <div class="d-f-a">
      <span class="span"
      >1.第一次開通免費贈送的使用期即將到期（{{
          startTime
        }}）後開始正常收費，保費由客戶承擔，保費會從客戶微信,支付寶或者銀行卡，自動扣費。每月
        720 元，合計 3 年 25920 元。</span
      >
        <span class="span"
        >2.本保單為理賠保單，為期 3 年，<span style="color: #ff0000"
        >即刻生效，如需關閉需聯繫投保人通過銀聯數字代碼關閉退保。</span
        ></span
        >
        <span class="span"
        >3.
        重要提示理財通賬戶保險業務如未在規定時間內關閉,會自動從您名下銀聯賬戶扣除等額保險費用。</span
        >
      </div>

      <div class="item" style="margin: 10px 0">
        <span>服务热线 </span>
      </div>
      <div class="d-j">
        <div class="qr">
          <img src="../assets/images/qr.png"/>
        </div>
        <div class="hotline">
          <h4>客服热线：<span style="color: #ff0000">95518</span></h4>
          <h4>财产热线：<span style="color: #ff0000">956008</span></h4>
          <h4>健康热线：<span style="color: #ff0000">400-6695518</span></h4>
          <h4>资金热线：<span style="color: #ff0000">400-8207999</span></h4>
        </div>
      </div>
      <div class="item" style="text-align: center; margin-top: 10px">
        <span>PICC 中国人民保险集团股份有限公司 </span>
      </div>
    </div>
  </div>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.container {
  line-height: 1.3;
  font-size: 12px;
  background: #fff;
  color: #000000;
}

.header::before {
  content: none; /* 移除伪元素 */
}
.header:after {
  content: none; /* 移除伪元素 */
}
.header {
  background: #4476a7;
  color: #fff;
  padding: 10px 20px;

}

.d-a-j {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.d-j {
  display: flex;
  justify-content: space-between;
}

img {
  width: 100%;
}

.body {
  padding: 0 15px;
}

.body .item {
  background: #ff0000;
  color: #fff;
  padding: 3px 2px;
}

.m-t {
  margin-bottom: 30px;
}

.d-f-a {
  display: flex;
  flex-direction: column;
}

.d-f-a .span {
  margin: 6px 0;
}

.left span {
  margin: 2px 0;
}

.qr {
  width: 150px;
}

.hotline {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>

