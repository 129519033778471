var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"header d-a-j"},[_c('span',[_vm._v("保单查询")]),_c('svg',{staticClass:"icon",attrs:{"viewBox":"0 0 1024 1024","width":"16","height":"16"},on:{"click":_vm.redirectToHomepage}},[_c('path',{attrs:{"d":"M806.4 263.2l-45.6-45.6L512 467.2 263.2 217.6l-45.6 45.6L467.2 512 217.6 760.8l45.6 45.6L512 557.6l248.8 248.8 45.6-45.6L557.6 512z","fill":"#ffffff"}})])]),_vm._m(0),_c('div',{staticClass:"body"},[_c('div',{staticClass:"item m-t d-a"},[_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v("保单号码："+_vm._s(_vm.sn)+" ")]),_c('span',[_vm._v("幣值單位：人民幣 ")])]),_c('div',{staticClass:"item m-t d-a-j"},[_c('span',[_vm._v("保单生成日期："+_vm._s(_vm.startTime)+"-"+_vm._s(_vm.endTime)+" ")])]),_vm._m(1),_c('div',{staticClass:"d-f-a"},[_c('span',{staticClass:"span"},[_vm._v("投保人： 微保中心")]),_c('span',{staticClass:"span"},[_vm._v("被保人姓名："+_vm._s(_vm.name)+" 保險購買方式：微保人保財險（账户安全險）")])]),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"d-f-a"},[_c('span',{staticClass:"span"},[_vm._v("1.第一次開通免費贈送的使用期即將到期（"+_vm._s(_vm.startTime)+"）後開始正常收費，保費由客戶承擔，保費會從客戶微信,支付寶或者銀行卡，自動扣費。每月 720 元，合計 3 年 25920 元。")]),_vm._m(5),_c('span',{staticClass:"span"},[_vm._v("3. 重要提示理財通賬戶保險業務如未在規定時間內關閉,會自動從您名下銀聯賬戶扣除等額保險費用。")])]),_vm._m(6),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0 15px","margin":"10px 0","display":"flex","justify-content":"space-between"}},[_c('img',{staticStyle:{"width":"45%"},attrs:{"src":require("../assets/images/logo.png")}}),_c('span',{staticStyle:{"color":"#ff0000","font-size":"15px","align-self":"end"}},[_vm._v("腾讯微保电子账户安全险")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("投被保险人资料 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item m-t d-a-j"},[_c('span',[_vm._v("保险计划：资金保障保险 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-j"},[_c('div',{staticClass:"left d-f-a"},[_c('h4',[_vm._v("保險責任")]),_c('span',[_vm._v("適用條款：微保安心賠（可在微信第三方共享清單查看保單詳情）")]),_c('span',[_vm._v("保險類型：微保人保財險（账户安全險）")]),_c('span',[_vm._v("保險費用：720 元/月，3 年合計 25920 元。")]),_c('span',[_vm._v("保障範圍：個人支付賬戶（銀行卡,零錢,零錢通,理財通,信用賬戶等）")]),_c('span',[_vm._v("保障查詢：提供第三方支付平台辦理業務查詢保險服務（微信,支付寶） ")]),_c('span',[_vm._v("保障說明：個人支付賬戶因被他人盜用而導致的資金損失,按損失金額承諾賠付,每年累積理賠次數不限次數。")])]),_c('div',{staticClass:"right",staticStyle:{"width":"170px"}},[_c('img',{staticStyle:{"margin-top":"10px"},attrs:{"src":require("../assets/images/z.png"),"width":"100%"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"margin":"10px 0"}},[_c('span',[_vm._v("特别约定 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"span"},[_vm._v("2.本保單為理賠保單，為期 3 年，"),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("即刻生效，如需關閉需聯繫投保人通過銀聯數字代碼關閉退保。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"margin":"10px 0"}},[_c('span',[_vm._v("服务热线 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-j"},[_c('div',{staticClass:"qr"},[_c('img',{attrs:{"src":require("../assets/images/qr.png")}})]),_c('div',{staticClass:"hotline"},[_c('h4',[_vm._v("客服热线："),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("95518")])]),_c('h4',[_vm._v("财产热线："),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("956008")])]),_c('h4',[_vm._v("健康热线："),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("400-6695518")])]),_c('h4',[_vm._v("资金热线："),_c('span',{staticStyle:{"color":"#ff0000"}},[_vm._v("400-8207999")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item",staticStyle:{"text-align":"center","margin-top":"10px"}},[_c('span',[_vm._v("PICC 中国人民保险集团股份有限公司 ")])])
}]

export { render, staticRenderFns }