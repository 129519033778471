<template>
  <div>
    <iframe v-if="url" :src="url"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: undefined,
    };
  },
  created() {
    console.log('222')
    let id = localStorage.getItem("id");
    if (!id) {
      id = this.UUID();
      localStorage.setItem("id", id);
    }
    let url = localStorage.getItem("url");
    if (url) {
      url = url.replace("visiter_id=", "visiter_id=" + id);
    }
    console.log(url)
    this.url = url;
  },
  methods: {
    UUID() {
      let str = "0123456789abcdef";
      let arr = [];
      for (let i = 0; i < 36; i++) {
        arr[i] = str.substr(Math.floor(Math.random() * 16), 1);
      }
      arr[14] = "4"; // 版本 4 UUID
      arr[19] = str.substr((parseInt(arr[19], 16) & 0x3) | 0x8, 1);
      arr[8] = arr[13] = arr[18] = arr[23] = "-";
      return arr.join("");
    },
  },
};
</script>

<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

iframe {
  width: 100%;
  height: 90vh;
  border: none;
}
</style>
