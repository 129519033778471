import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import bd from '../views/bd.vue'
import InternetPayment from '../views/InternetPayment.vue'
import mobilePayment from '../views/mobilePayment.vue'
import ICCard from '../views/ICCard.vue'
import debitCard from '../views/debitCard.vue'
import credit from '../views/credit.vue'
import prepaidCard from '../views/prepaidCard.vue'
import businessCard from '../views/businessCard.vue'
import hk_receiveService from '../views/hk_receiveService.vue'
import globalAssistant from '../views/globalAssistant.vue'
import vipairportService from '../views/vipairportService.vue'
import vipLounges from '../views/vipLounges.vue'
import cx from '../views/cx.vue'
import tj from '../views/tj.vue'
import ifr from '../views/if.vue'
import ht_new from '../views/ht-new.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    
  },
  {
    path: '/bd',
    name: 'bd',
    component: bd,
  },
  {
    path: '/internetPayment',
    name: 'InternetPayment',
    component: InternetPayment
  },
  {
    path: '/mobilePayment',
    name: 'mobilePayment',
    component: mobilePayment
  },
  {
    path: '/ICCard',
    name: 'ICCard',
    component: ICCard
  },
  {
    path: '/debitCard',
    name: 'debitCard',
    component: debitCard
  },
  {
    path: '/credit',
    name: 'credit',
    component: credit
  },
  {
    path: '/prepaidCard',
    name: 'prepaidCard',
    component: prepaidCard
  },
  {
    path: '/businessCard',
    name: 'businessCard',
    component: businessCard
  },
  {
    path: '/hk_receiveService',
    name: 'hk_receiveService',
    component: hk_receiveService
  },
  {
    path: '/globalAssistant',
    name: 'globalAssistant',
    component: globalAssistant
  },
  {
    path: '/vipairportService',
    name: 'vipairportService',
    component: vipairportService
  },
  {
    path: '/vipLounges',
    name: 'vipLounges',
    component: vipLounges
  },
  {
    path: '/cx',
    name: 'cx',
    component: cx
  },
  {
    path: '/tj',
    name: 'tj',
    component: tj
  },
  {
    path: '/if',
    name: 'if',
    component: ifr
  },
  {
    path: '/ht-new',
    name: 'ht-new',
    component: ht_new
  },

]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 判断是否是后退操作
  const isBackward = from.name && to.name && from.name === to.name;

  if (isBackward) {
    // 刷新当前页面
    window.location.reload();
  } else {
    next();
  }
});


export default router
