<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="less">

</style>
<script>
export default {
  name: 'App',
  mounted() {
    window.addEventListener('popstate', this.handlePopState);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.handlePopState);
  },
  methods: {
    handlePopState(event) {
      location.reload();
    }
  }
};
</script>
