import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'vant/lib/index.css'; // 引入 Vant 的样式文件

Vue.config.productionTip = false

// 解决bug：页面/路由跳转后，滚动条消失，页面无法滚动
router.afterEach((to, from, next) => {
  document.querySelector("body").setAttribute("style", "overflow: auto !important;")
});
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
