<template>
  <div class="pop-bg" v-show="showPopup">
    <div class="bg" @click="hidePopup"></div>

    <div class="pop">
      <input type="button" class="btclose" @click="hidePopup" value="關閉">
      <div class="bd-title">查詢保單</div>
      <div class="pop-form-item">
        <input type="text" v-model="name" placeholder="姓名">
      </div>
      <div class="pop-form-item">
        <input type="text" v-model="code" maxlength="8" placeholder="輸入您的號碼">
      </div>
      <div class="pop-btn" @click="redirectToNewPage">查詢</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      code: "",
      showPopup: true,
    };
  },
  methods: {
    hidePopup() {
      this.showPopup = false;
    },
    redirectToNewPage() {
      var name = this.name.trim();
      var code = this.code.trim();

      if (!name || !code) {
        alert("姓名和號碼不能爲空！");
        return;
      }

      // 使用Vue Router进行页面跳转，并传递参数
      this.$router.push({
        path: '/ht-new',
        query: { name, code }
      });
    }
  },
};
</script>

<style scoped>
html,
body {
  width: 100%;
}

.page-header {
}

.page-header .logo {
  width: 100%;
}

.page-navs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.page-navs .page-nav {
  text-align: center;
  background-color: #ff9200;
  border-radius: 5px;
  padding: 10px;
  width: 25.333%;
  height: 25vw;
  color: #fff;
}

.page-navs .page-nav img {
  width: 30px;
}

.page-navs .page-nav .page-nav-title {
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
}

.page-navs .page-nav .page-nav-mark {
  font-size: 11px;
  font-weight: 400;
  margin-top: 5px;
  color: #eee;
  text-align: left;
}

.container {
  padding: 10px 15px;
}

.page-menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
  padding: 20px 20px;
}

.item-bg {
  /* background: url(/img/item-bg.png) no-repeat; */
  background-size: 100% 100%;
  background-position: center center;
}

.page-menus .menu {
  width: 25%;
  text-align: center;
}

.page-menus .menu > div {
  text-align: center;
  font-size: 12px;
  color: #818181;
  margin: 0 auto;
  margin-bottom: 10px;
}

.page-menus .menu img {
  width: 50px;
  display: block;
  margin: 0 auto;
  margin-bottom: 5px;
}

.page-content {
  margin-top: 10px;
  padding: 10px;
}

.common-title {
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
}

.page-content .items .item {
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.items .item .item-left {
  width: 60px;
  margin: 0 10px;
}

.items .item .item-right {
  flex: 1;
  padding-left: 10px;
}

.items .item .item-right img {
  width: 70%;
}

.page-content .items .item .item-mark {
  font-size: 14px;
  color: #a95e24;
  margin-top: 5px;
}

.page-hz {
  margin-top: 10px;
}

.page-hz .title {
  text-align: center;
  font-size: 20px;
  padding: 20px 0;
}

.page-hz .hz-imgs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.page-hz .hz-imgs .hz-img {
  width: 22%;
  margin-left: 2.5%;
  margin-bottom: 15px;
}

.page-hzhb {
  margin-top: 10px;
}

.page-hzhb .title {
  text-align: center;
  font-size: 20px;
  padding: 20px 0;
}

.page-hzhb .hzhb-imgs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.page-hzhb .hzhb-imgs .hzhb-img {
  width: 22%;
  margin-left: 2.5%;
  margin-bottom: 15px;
}

.float-btn {
  z-index: 20;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  right: 2vw;
  top: 50%;
  text-align: center;
  font-size: 1.6vw;
}

.float-btn img {
  width: 18vw;
}

.pop-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.pop-bg .bg {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.pop-bg.hide {
  display: none;
}

.pop-doc-header {
  display: none;
}

.pop {
  position: absolute;
  top: 50%;
  left: 50px;
  right: 50px;
  min-height: 240px;
  transform: translateY(-50%);
  z-index: 99;
  /*background: url(/img/popbg.png) no-repeat;*/
  background-position: center center;
  background-size: 100% 100%;
  border-radius: 10px;
}

.pop .pop-title {
  padding: 20px;
  text-align: center;
  color: #fff;
  font-size: 18px;
}

.pop .pop-form-item {
  text-align: center;
  margin-top: 20px;
}

.pop .pop-form-item input {
  width: 80%;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  font-size: 16px;
  color: #ffa752;
  border-radius: 5px;
  border: #ffa752 2px solid;
}

.pop .pop-btn {
  width: 85%;
  height: 50px;
  background: #ffa752;
  color: #fff;
  text-align: center;
  line-height: 50px;
  border-radius: 8px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.layui-layer-loading {
  left: 50% !important;
  transform: translateX(-50%) !important;
}

.layui-layer-loading .layui-layer-content {
  font-size: 16px !important;
}

/***
 ä¿å•æŸ¥çœ‹
*/
.doc-header {
  padding: 10px 0;
}

.doc-header img {
  width: 110px;
}

.doc-title {
  font-size: 16px;
  text-align: center;
  margin: 20px 0px;
}

.doc-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 10px;
}

.doc-info .info {
  width: 50%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-size: 10px;
}

.doc-info .info.bold {
  font-size: 12px;
  font-weight: bold;
}

.doc-info .info .content {
  color: red;
}

.doc-yq {
  font-size: 12px;
  padding: 10px;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  margin-top: 10px;
}

.doc-content {
  font-size: 12px;
  font-weight: 500;
  color: #000;
  padding: 0 10px;
}

.doc-content span {
  display: block;
  margin-top: 4px;
  text-indent: 20px;
}

.doc-content div {
  margin-top: 5px;
}

.doc-content .r {
  margin-top: 5px;
  text-align: right;
}

.page-gz {
  width: 10%;
  margin-left: 85%;
  margin-bottom: 5px;
  /* background-image: url("/img/gz.png") */
}

.image-wrapper {
  position: relative;
}

.text-overlay {
  position: absolute;
  width: 190px;
  margin-left: 190px;
  margin-bottom: 5px;

  top: 0;
  left: 0;
}

.title-subject {
  margin-top: 10px;
  font-weight: bold;
  color: #666;
}

.title-subject-sub {
  color: #959595;
}

#chayan .chayan-body {
  color: #595959;
  padding: 15px 20px;
}

.chayan-title {
  font-size: 16px;
  text-align: left;

  margin: 0 1rem 1.5rem;
}

.chayan-form {
  margin: 0 1rem;
}

.chayan-btn-container {
  text-align: center;
  margin-top: 40px;
}

.chayan-btn {
  width: 80%;
  border-radius: 5px;
  background-color: #f1a841;
  font-size: 1.2rem;
  line-height: 2.6rem;
  height: 2.8rem;
}

.chayan-content {
  text-align: center;
  font-size: 16px;
  line-height: 2rem;
  font-weight: bold;
}

.hidden {
  display: none;
}

.chayan-content.hidden {
  display: none;
}

.chayan-result {
  border: 1px solid #e8d598;
  padding: 1.4rem 0;
}

.chayan-avatar {
  height: 100px;
  margin-bottom: 1rem;
  /* background: url("/img/aaaa.png") no-repeat center center; */
  background-size: auto 100%;
}

.chayan-avatar img {
  border-radius: 50rem;
  width: auto;
  height: 70%;
}

.chayan-tip {
  color: #dea930;
  font-weight: lighter;
  margin: 0 5px;
}

.result-tip {
  margin: 30px 0 15px;
  font-size: 16px;
}

.chayan-footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  color: #9f9f9f;
  font-size: 10px;
  width: 90%;
}

.chayan-footer .layui-row {
  margin-bottom: 5px;
}

.chayan-platform-logo img {
  width: 60%;
  max-width: 344px;
  height: auto;
}

.chayan-platform-name {
  letter-spacing: 0.3rem;
  margin: 0.6rem auto 1rem !important;
}

.chayan-running {
  line-height: 2rem;
}

.layui-layer-title {
  border-bottom-color: #fff;
}

/**  weixin */
#weixin-tip {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  filter: alpha(opacity=80);
  width: 100%;
  height: 100%;
  z-index: 100;
}

#weixin-tip p {
  text-align: center;
  margin-top: 40%;
  padding: 0 5%;
}

#weixin-tip img {
  width: 100%;
}

/** cs-modal */
.cs-modal-container {
  padding: 0 1rem 1rem;
}

.cs-list-item {
  display: flex;
  flex-flow: row;
  border-top: 1px solid #efefef;
  padding: 1rem 0 0;
}

.cs-list-icon {
  flex: 1;
}

.cs-list-icon img {
  width: 4.5rem;
}

.cs-list-name {
  flex: 2;
  line-height: 4rem;
}

.cs-list-link {
  flex: 1;
  flex-flow: column;
}
.cs-list-link .layui-btn {
  display: block;
  margin-left: 0 !important;
  margin-bottom: 0.6rem;
}

.pop-bg {
  color: rgba(0, 0, 0, 0.85);
  font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.pop {
  color: rgba(0, 0, 0, 0.85);
  font: 14px Helvetica Neue, Helvetica, PingFang SC, Tahoma, Arial, sans-serif;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 50%;
  left: 50px;
  right: 50px;
  min-height: 240px;
  transform: translateY(-50%);
  z-index: 99;
  background: white;
  background-position: center center;
  background-size: 100% 100%;
  border-radius: 10px;
}
.bd-title {
  margin-top: 10px;
  font-size: 20px;
  text-align: center;
}
.btclose {
  margin-left: 84%;
  margin-top: 10px;
  background-color: white;
  border: 0px solid;
}
.tbtext {
  padding: 10px;
  font-size: 16px;
  display: inline-block;
  vertical-align: middle;
  color: red;
}
</style>
